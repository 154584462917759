import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'
import JwtService from '../utils/jwt.service'
import StorageService from '../utils/storage.service'

const auth = {
  namespaced: true,
  state: {
    user: StorageService.getUser() || null,
    token: JwtService.getToken() || null,
    loading: false
  },
  mutations: {
    SET_VALUE (state, { name, payload }) {
      state[name] = payload
    },
    UPDATE_PROPERTY (state, { name, payload }) {
      state[name] = { ...state[name], ...payload }
    },
    LOGIN_START (state) {
      state.loading = true
    },
    LOGIN_SUCCESS (state, action = {}) {
      state.loading = false
      state.user = action.user || state.user
      state.token = action.token || state.token
    }
  },
  actions: {
    async login ({ commit }, payload) {
      commit('SET_VALUE', { name: 'loading', payload: true })

      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.users.login, payload)
          .then(({ data }) => {
            const user = data
            const token = user.bearerId
            JwtService.saveToken(token)
            StorageService.saveUser(user)
            commit('LOGIN_SUCCESS', { user, token })
            commit('SET_VALUE', { name: 'loading', payload: false })
            ApiService.setAuthHeader()
            resolve(data)
          })
          .catch(error => {
            commit('SET_VALUE', { name: 'loading', payload: false })
            reject(error)
          })
      })
    },
    async logout ({ commit }) {
      JwtService.destroyToken()
      StorageService.destroyUser()
      commit('SET_VALUE', { name: 'user', payload: null })
      commit('SET_VALUE', { name: 'token', payload: null })
    }
  },
  getters: {
  }
}

export default auth
