import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'

const user = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async fetchUpocs (context, params={}) {
      return ApiService.query(apiPath.upocs.index, { params: {...params} })
    },
    async importCSV (store, formData) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.upocs.import, formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  getters: {
  }
}

export default user
