import Vue from 'vue'
import Router from 'vue-router'
import JwtService from '../utils/jwt.service'
import StorageService from '../utils/storage.service'
import routes from './routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  const token = JwtService.getToken()
  const user = StorageService.getUser()

  const roleHomeRoute = {
    'superadmin': 'order',
    'admin': 'order',
    'poweruser': 'order',
    'controller': 'order',
    'order-provider': 'order',
    'print-house': 'print',
    'billing': 'analytics'
  }

  const { requiresRole } = to.meta
  if (requiresRole && !requiresRole.includes(user.role) && user && user.role) {
    // Handle protected routes based on user role
    next({
      name: roleHomeRoute[user.role]
    })
  } else if (!to.name && user && user.role) {
    next({
      name: roleHomeRoute[user.role]
    })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token || !user) {
      // Destroy stored user info and jwt
      StorageService.destroyUser()
      JwtService.destroyToken()

      const to = { name: 'login' }
      if (to.fullPath !== '/') {
        to.query = {
          nextUrl: to.fullPath
        }
      }
      // Redirect guest user to sign in page
      next(to)
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token && user) {
      // Redirect logged in user to find page
      next({
        name: roleHomeRoute[user.role]
      })
    } else {
      next()
    }
  } else {
    next({
      name: 'login'
    })
  }
})

export default router