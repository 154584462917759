import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import store from './store/'
import ApiService from './utils/api.service'
import moment from 'moment'
import VueConfirmDialog from 'vue-confirm-dialog'
import DateRangePicker from 'vue2-daterange-picker'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
Vue.use(VueConfirmDialog)

Vue.component('date-range-picker', DateRangePicker)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss')
  }
})

// Init Axios Setup
ApiService.init()
ApiService.setHeader()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
