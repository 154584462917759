import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'

const initialOrders = () => ({
  data: null,
  loading: false,
  pageNum: 1,
  pageLen: 10
})

const order = {
  namespaced: true,
  state: {
    orders: initialOrders(),
    analytics: null,
    dashboardAnalytics: null,
    collections: null
  },
  mutations: {
    SET_VALUE (state, { name, payload }) {
      state[name] = payload
    },
    UPDATE_PROPERTY (state, { name, payload }) {
      state[name] = { ...state[name], ...payload }
    },
    DELETE_ORDER (state, orderId) {
      const copy = { ...state.orders }
      copy.data.docs = copy.data.docs.filter(doc => doc._id !== orderId)
      state.orders = copy
    },
    UPDATE_ORDER (state, { _id, payload }) {
      const copy = { ...state.orders }
      copy.data.docs = copy.data.docs.map(doc => {
        if (doc._id === _id) {
          doc = {
            ...doc,
            ...payload
          }
        }
        return doc
      })
      state.orders = copy
    },
    RESET_ORDERS (state) {
      state.orders = initialOrders()
    },
    DELETE_COLLECTION (state, orderId) {
      const copy = { ...state.collections }
      copy.docs = copy.docs.filter(doc => doc._id !== orderId)
      state.collections = copy
    }
  },
  actions: {
    async setPageNum ({ commit }, pageNum) {
      commit ('UPDATE_PROPERTY', { name: 'orders', payload: { pageNum } })
    },
    async fetchOrders ({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_PROPERTY', { name: 'orders', payload: { loading: true } })
        ApiService.query(apiPath.orders.index, {
          params: {
            ...params,
            page_num: state.orders.pageNum,
            page_len: state.orders.pageLen
          }
        })
          .then(({ data }) => {
            commit('UPDATE_PROPERTY', { name: 'orders', payload: { loading: false, data: data } })
            resolve(data)
          })
          .catch((error) => {
            commit('UPDATE_PROPERTY', { name: 'orders', payload: { loading: false } })
            reject(error)
          })
      })
    },
    async fetchAllOrders (context, params) {
      return ApiService.query(apiPath.orders.index, { params })
    },
    async fetchOrderBundle (store, orderId) {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.orders.bundle.replace(':orderId', orderId), { responseType: 'blob' })
          .then((resp) => {
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchOrderAnalytics ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_VALUE', { name: 'analytics', payload: null })
        ApiService.query(apiPath.orders.analytics)
          .then(({ data }) => {
            commit('SET_VALUE', { name: 'analytics', payload: data })
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchDashboardAnalytics ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_VALUE', { name: 'dashboardAnalytics', payload: null })
        ApiService.query(apiPath.orders.dashboardAnalytics)
          .then(({ data }) => {
            commit('SET_VALUE', { name: 'dashboardAnalytics', payload: data })
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchOrderCollections ({ commit }) {
      return new Promise((resolve,) => {
        commit('SET_VALUE', { name: 'collections', payload: null })
        ApiService.query(apiPath.orders.collection)
          .then(({ data }) => {
            commit('SET_VALUE', { name: 'collections', payload: data })
            resolve(data)
          })
          .catch(() => {
            resolve()
          })
      })
    },
    async grantOrder ({ commit }, order) {
      const payload = { status: order.status, granted: true }
      return new Promise((resolve, reject) => {
        ApiService.put(apiPath.orders.detail.replace(':orderId', order._id), payload)
          .then((resp) => {
            commit('UPDATE_ORDER', { _id: order._id, payload: { granted: true }})
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchOptionOrder (context, orderId) {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.orders.option.replace(':orderId', orderId))
          .then((resp) => {
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async printOrder ({ commit }, orderId) {
      const payload = { status: 'printed' }
      return new Promise((resolve, reject) => {
        ApiService.put(apiPath.orders.detail.replace(':orderId', orderId), payload)
          .then((resp) => {
            commit('UPDATE_ORDER', { _id: orderId, payload: { status: 'printed' }})
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async billOrder ({ commit }, order) {
      const payload = { status: 'billed' }
      return new Promise((resolve, reject) => {
        ApiService.put(apiPath.orders.detail.replace(':orderId', order._id), payload)
          .then((resp) => {
            commit('UPDATE_ORDER', { _id: order._id, payload: { billedAt: resp.data.billedAt }})
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async deleteOrder ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        ApiService.delete(apiPath.orders.detail.replace(':orderId', orderId))
          .then((resp) => {
            commit('DELETE_ORDER', orderId)
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async resetOrders ({ commit }) {
      commit('RESET_ORDERS')
    }
  },
  getters: {
  }
}

export default order
