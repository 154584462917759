const ID_USER_KEY = 'id_user'

const getItem = (key) => {
  const itemJson = window.localStorage.getItem(key)
  let item = null

  try {
    item = JSON.parse(itemJson)
  } catch (e) {
    destroyItem(key)
  }
  return item
}

const saveItem = (key, item) => {
  window.localStorage.setItem(key, JSON.stringify(item))
}

const destroyItem = (key) => {
  window.localStorage.removeItem(key)
}

const getUser = () => getItem(ID_USER_KEY)
const saveUser = (item) => saveItem(ID_USER_KEY, item)
const destroyUser = () => destroyItem(ID_USER_KEY)

export default {
  getUser,
  saveUser,
  destroyUser
}
