import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'

const initialUsers = () => ({
  data: null,
  loading: false,
  pageNum: 1,
  pageLen: 10
})

const user = {
  namespaced: true,
  state: {
    users: initialUsers()
  },
  mutations: {
    SET_VALUE (state, { name, payload }) {
      state[name] = payload
    },
    UPDATE_PROPERTY (state, { name, payload }) {
      state[name] = { ...state[name], ...payload }
    },
    DELETE_USER (state, userId) {
      const copy = { ...state.users }
      copy.data.docs = copy.data.docs.filter(doc => doc._id !== userId)
      state.users = copy
    },
    ADD_USER (state, payload) {
      const copy = { ...state.users }
      copy.data.docs = [...copy.data.docs, payload]
      state.users = copy
    },
    UPDATE_USER (state, payload) {
      const copy = { ...state.users }
      copy.data.docs = copy.data.docs.map(doc => {
        if (doc._id === payload._id) {
          doc = {
            ...doc,
            ...payload
          }
        }
        return doc
      })
      state.users = copy
    }
  },
  actions: {
    async setPageNum ({ commit }, pageNum) {
      commit ('UPDATE_PROPERTY', { name: 'users', payload: { pageNum } })
    },
    async fetchUsers ({ commit, state }, params={}) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_PROPERTY', { name: 'users', payload: { loading: true } })
        ApiService.query(apiPath.users.index, {
          params: {
            ...params,
            page_num: state.users.pageNum,
            page_len: state.users.pageLen
          }
        })
          .then(({ data }) => {
            commit('UPDATE_PROPERTY', { name: 'users', payload: { loading: false, data: data } })
            resolve(data)
          })
          .catch((error) => {
            commit('UPDATE_PROPERTY', { name: 'users', payload: { loading: false } })
            reject(error)
          })
      })
    },
    async fetchUser (context, userId) {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.users.detail.replace(':userId', userId))
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchAdminSettings () {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.users.adminSettings)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async deleteUser ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        ApiService.delete(apiPath.users.detail.replace(':userId', userId))
          .then((resp) => {
            commit('DELETE_USER', userId)
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async createUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.users.index, payload)
          .then(({ data }) => {
            commit('ADD_USER', data)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async updateUser (context, { userId, body }) {
      return new Promise((resolve, reject) => {
        ApiService.put(apiPath.users.detail.replace(':userId', userId), body)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async uploadBrief (store, formData) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.users.uploadBrief, formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async getBrief (store, params) {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.users.brief, { params, responseType: 'blob' })
          .then((resp) => {
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
  getters: {
  }
}

export default user
