import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'

const initialGroups = () => ({
  data: null,
  loading: false,
  pageNum: 1,
  pageLen: 10
})

const group = {
  namespaced: true,
  state: {
    groups: initialGroups()
  },
  mutations: {
    SET_VALUE (state, { name, payload }) {
      state[name] = payload
    },
    UPDATE_PROPERTY (state, { name, payload }) {
      state[name] = { ...state[name], ...payload }
    },
    DELETE_GROUP (state, groupId) {
      const copy = { ...state.groups }
      copy.data.docs = copy.data.docs.filter(doc => doc._id !== groupId)
      state.groups = copy
    },
    ADD_GROUP (state, payload) {
      const copy = { ...state.groups }
      copy.data.docs = [...copy.data.docs, payload]
      state.groups = copy
    },
    UPDATE_GROUP (state, payload) {
      const copy = { ...state.groups }
      copy.data.docs = copy.data.docs.map(doc => {
        if (doc._id === payload._id) {
          doc = {
            ...doc,
            ...payload
          }
        }
        return doc
      })
      state.groups = copy
    }
  },
  actions: {
    async setPageNum ({ commit }, pageNum) {
      commit ('UPDATE_PROPERTY', { name: 'groups', payload: { pageNum } })
    },
    async fetchGroups ({ commit, state }, params={}) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_PROPERTY', { name: 'groups', payload: { loading: true } })
        ApiService.query(apiPath.groups.index, {
          params: {
            ...params,
            page_num: state.groups.pageNum,
            page_len: state.groups.pageLen
          }
        })
          .then(({ data }) => {
            commit('UPDATE_PROPERTY', { name: 'groups', payload: { loading: false, data: data } })
            resolve(data)
          })
          .catch((error) => {
            commit('UPDATE_PROPERTY', { name: 'groups', payload: { loading: false } })
            reject(error)
          })
      })
    },
    async fetchOrderProviders () {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.groups.orderProviders)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchGroup (context, groupId) {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.groups.detail.replace(':groupId', groupId))
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async deleteGroup ({ commit }, groupId) {
      return new Promise((resolve, reject) => {
        ApiService.delete(apiPath.groups.detail.replace(':groupId', groupId))
          .then((resp) => {
            commit('DELETE_GROUP', groupId)
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async createGroup ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.groups.index, payload)
          .then(({ data }) => {
            commit('ADD_GROUP', data)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async updateGroup (context, { groupId, body }) {
      return new Promise((resolve, reject) => {
        ApiService.put(apiPath.groups.detail.replace(':groupId', groupId), body)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
  getters: {
  }
}

export default group
