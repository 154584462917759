import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from './jwt.service'
const API_URL = process.env.VUE_APP_API_URL

const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },

  setHeader () {
    const jsonUtf8 = 'application/json'
    Vue.axios.defaults.headers.common.Accept = jsonUtf8
    Vue.axios.defaults.headers.common['Content-Type'] = jsonUtf8

    this.setAuthHeader()
  },

  setAuthHeader () {
    const token = JwtService.getToken()
    if (token) {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  },

  removeAuthHeader () {
    delete Vue.axios.defaults.headers.common.Authorization
  },

  getBaseURL () {
    return API_URL
  },

  query (resource, params) {
    return Vue.axios.get(resource, params)
      .then((response) => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  get (resource, slug = '', config) {
    return Vue.axios.get(`${resource}/${slug}`, config)
      .then((response) => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  post (resource, params) {
    return Vue.axios.post(`${resource}`, params)
      .then((response) => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  update (resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
      .then((response) => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  put (resource, params) {
    return Vue.axios.put(`${resource}`, params)
      .then((response) => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  delete (resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw error
    })
  }
}

export default ApiService
