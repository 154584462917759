import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          requiresAuth: true,
          requiresRole: ['poweruser', 'controller', 'order-provider']
        },
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: '/order/:status',
        name: 'order',
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider', 'billing']
        },
        component: () => import('../views/Order.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin', 'poweruser', 'controller']
        },
        component: () => import('../views/User.vue')
      },
      {
        path: '/group',
        name: 'group',
        meta: {
          requiresAuth: true,
          requiresRole: ['poweruser']
        },
        component: () => import('../views/Group.vue')
      },
      {
        path: '/print',
        name: 'print',
        meta: {
          requiresAuth: true,
          requiresRole: ['print-house']
        },
        component: () => import('../views/Print.vue')
      },
      {
        path: '/analytics',
        name: 'analytics',
        meta: {
          requiresAuth: true,
          requiresRole: ['billing']
        },
        component: () => import('../views/Analytics.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        meta: {
          requiresAuth: true,
          requiresRole: ['admin', 'poweruser', 'controller']
        },
        component: () => import('../views/Settings.vue')
      },
      {
        path: '/address-book',
        name: 'addressBook',
        meta: {
          requiresAuth: true,
          requiresRole: ['poweruser']
        },
        component: () => import('../views/AddressBook.vue')
      },
      {
        path: '/street-directory',
        name: 'streetDirectory',
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin']
        },
        component: () => import('../views/StreetDirectory.vue')
      },
      {
        path: '/collection',
        name: 'collection',
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider']
        },
        component: () => import('../views/Collection.vue')
      },
      {
        path: '/server-status',
        name: 'serverStatus',
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin']
        },
        component: () => import('../views/ServerStatus.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          guest: true
        },
        component: () => import('../views/Login.vue')
      },
      {
        path: '/token-login',
        name: 'tokenLogin',
        meta: {
          guest: true
        },
        component: () => import('../views/TokenLogin.vue')
      }
    ]
  }
]

export default routes
