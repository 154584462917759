import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'

const address = {
  namespaced: true,
  state: {
    serverStatus: null
  },
  mutations: {
    SET_VALUE (state, { name, payload }) {
      state[name] = payload
    },
    UPDATE_PROPERTY (state, { name, payload }) {
      state[name] = { ...state[name], ...payload }
    }
  },
  actions: {
    async fetchServerStatus ({ commit }) {
      return new Promise((resolve, reject) => {
        ApiService.query(apiPath.server.status)
          .then(({ data }) => {
            commit('SET_VALUE', { name: 'serverStatus', payload: data })
            resolve(data)
          })
          .catch((error) => {
            commit('SET_VALUE', { name: 'serverStatus', payload: null })
            reject(error)
          })
      })
    }
  },
  getters: {
  }
}

export default address
