import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import order from './order'
import user from './user'
import group from './group'
import address from './address'
import server from './server'
import upocs from './upocs'
import sender from './sender'

// Make vue aware of Vuex
Vue.use(Vuex)

const modules = {
  auth,
  order,
  user,
  group,
  address,
  server,
  upocs,
  sender
}

// Combine the initial state and the mutations to create a Vuex store.
// This store can be linked to our app.
export default new Vuex.Store({
  modules
})
