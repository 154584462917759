<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
              <img src="@/assets/logo-inverted.png" />
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                  <span class="navbar-toggler-icon invisible"></span>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                              <img src="@/assets/logo-inverted.png" />
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
                <!--Divider-->
                <hr class="my-3">
                <!--Heading-->
                <div class="mt-auto">
                  <ul class="navbar-nav mb-3">
                    <slot name="bottom-links">
                    </slot>
                  </ul>
                  <h6 v-if="user" class="navbar-heading text-primary">{{ user.firstName }} {{ user.lastName }}</h6>
                </div>
                <!--Navigation-->
                <ul class="navbar-nav mb-md-3">
                    <li class="nav-item">
                        <a class="nav-link cursor-pointer" @click="handleLogout">
                            <i class="ni ni-user-run"></i> Logout
                        </a>
                    </li>
                </ul>
            </div>
            </div>
    </nav>
</template>
<script>
import { mapState } from 'vuex'
import NavbarToggleButton from '@/components/NavbarToggleButton'

export default {
  name: 'sidebar',
  components: {
    NavbarToggleButton
  },
  props: {
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true)
    },
    handleLogout () {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.replace({ name: 'login' })
        })
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
