<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Hybridpost"
      title="Hybridpost"
    >
      <template slot="links">
        <sidebar-item v-for="(link, index) in shownLinks" :key="index" :link="link"/>
      </template>
      <template slot="bottom-links">
        <sidebar-item v-for="(link, index) in shownBottomLinks" :key="index" :link="link"/>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { FadeTransition } from 'vue2-transitions';

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      links: [
        { name: 'Dashboard', icon: 'fa fa-tachometer-alt text-primary', path: '/dashboard', roles: ['poweruser', 'controller', 'order-provider'] },
        { name: 'Offene Briefe', icon: 'ni ni-single-copy-04 text-primary', path: '/order/offene', roles: ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider'] },
        { name: 'Freigegebene Briefe', icon: 'ni ni-check-bold text-primary', path: '/order/freigegebene', roles: ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider'] },
        { name: 'Fertige Briefe', icon: 'fa fa-print text-primary', path: '/order/fertige', roles: ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider'] },
        { name: 'Abgerechnete Briefe', icon: 'fa fa-file-invoice-dollar text-primary', path: '/order/abgerechnete', roles: ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider', 'billing'] },
        { name: 'Benutzerverwaltung', icon: 'ni ni-single-02 text-primary', path: '/user', roles: ['superadmin', 'admin', 'poweruser', 'controller'] },
        { name: 'Gruppen', icon: 'fa fa-users text-primary', path: '/group', roles: ['poweruser'] },
        { name: 'Straßenverzeichnis', icon: 'ni ni-square-pin text-primary', path: '/street-directory', roles: ['superadmin'] },
        { name: 'Druck Freigegeben', icon: 'fa fa-print text-primary', path: '/print', roles: ['print-house'] },
        { name: 'Auswertung', icon: 'fa fa-print text-primary', path: '/analytics', roles: ['billing'] },
        { name: 'Offene Sammelmappen', icon: 'fa fa-folder-open text-primary', path: '/collection', roles: ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider'] },
        { name: 'Serverstatus', icon: 'fa fa-server text-primary', path: '/server-status', roles: ['superadmin'] },
        { name: 'Adressbuch', icon: 'fa fa-address-book text-primary', path: '/address-book', roles: ['poweruser'] },
      ],
      bottomLinks: [
        { name: 'Eigenschaften', icon: 'fa fa-cog text-primary', path: '/settings', roles: ['admin', 'poweruser', 'controller'] },
        { name: 'Impressum', icon: 'fa fa-file-contract text-primary', path: 'https://sent24.de/impressum', href: 'https://sent24.de/impressum', target: '_blank' },
        { name: 'Datenschutz', icon: 'fa fa-user-shield text-primary', path: 'https://sent24.de/datenschutz', href: 'https://sent24.de/datenschutz', target: '_blank' }
      ],
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    ...mapState('order', [
      'collections'
    ]),
    role () {
      return this.user && this.user.role
    },
    addressBookEnabled () {
      return this.user && (this.user.allowEinschreiben || this.user.allowPZA)
    },
    manageGroupEnabled () {
      return this.user && this.user.allowManageGroup
    },
    shownLinks () {
      let shownLinks = this.links.filter(link => link.roles.includes(this.role))

      // Show collection only if user collections exist
      const collectionIndex = shownLinks.findIndex(link => link.name === 'Offene Sammelmappen')
      if (collectionIndex !== -1 && (!this.collections || !this.collections.totalDocs)) {
        shownLinks.splice(collectionIndex, 1)
      }
      if (!this.addressBookEnabled) {
        shownLinks = shownLinks.filter(link => link.name !== 'Adressbuch')
      }
      if (!this.manageGroupEnabled) {
        shownLinks = shownLinks.filter(link => link.name !== 'Gruppen')
      }
      return shownLinks
    },
    shownBottomLinks () {
      return this.bottomLinks.filter(link => !link.roles || link.roles.includes(this.role))
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  mounted () {
    this.$store.dispatch('order/fetchOrderCollections')
  }
};
</script>
<style lang="scss">
</style>
