import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'

const initialSenders = () => ({
  data: null,
  loading: false,
  pageNum: 1,
  pageLen: 10
})

const address = {
  namespaced: true,
  state: {
    senders: initialSenders()
  },
  mutations: {
    SET_VALUE (state, { name, payload }) {
      state[name] = payload
    },
    UPDATE_PROPERTY (state, { name, payload }) {
      state[name] = { ...state[name], ...payload }
    },
    DELETE_SENDER (state, senderId) {
      const copy = { ...state.senders }
      copy.data.docs = copy.data.docs.filter(doc => doc._id !== senderId)
      state.senders = copy
    },
    ADD_SENDER (state, payload) {
      const copy = { ...state.senders }
      copy.data.docs = [payload, ...copy.data.docs]
      state.senders = copy
    },
    UPDATE_SENDER (state, payload) {
      const copy = { ...state.senders }
      copy.data.docs = copy.data.docs.map(doc => {
        if (doc._id === payload._id) {
          doc = {
            ...doc,
            ...payload
          }
        }
        return doc
      })
      state.senders = copy
    }
  },
  actions: {
    async setPageNum ({ commit }, pageNum) {
      commit ('UPDATE_PROPERTY', { name: 'senders', payload: { pageNum } })
    },
    async fetchSenders ({ commit, state }, params={}) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_PROPERTY', { name: 'senders', payload: { loading: true } })
        ApiService.query(apiPath.senders.index, {
          params: {
            ...params,
            page_num: state.senders.pageNum,
            page_len: state.senders.pageLen
          }
        })
          .then(({ data }) => {
            commit('UPDATE_PROPERTY', { name: 'senders', payload: { loading: false, data: data } })
            resolve(data)
          })
          .catch((error) => {
            commit('UPDATE_PROPERTY', { name: 'senders', payload: { loading: false } })
            reject(error)
          })
      })
    },
    async deleteSender ({ commit }, senderId) {
      return new Promise((resolve, reject) => {
        ApiService.delete(apiPath.senders.detail.replace(':senderId', senderId))
          .then((resp) => {
            commit('DELETE_SENDER', senderId)
            resolve(resp)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async createSender ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.senders.index, payload)
          .then(({ data }) => {
            commit('ADD_SENDER', data)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async updateSender (context, { senderId, body }) {
      return new Promise((resolve, reject) => {
        ApiService.put(apiPath.senders.detail.replace(':senderId', senderId), body)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  getters: {
  }
}

export default address
